import { Button } from "@/Components/ui/button";
import { Card } from "@/Components/ui/card";
import { navigate } from "@/Hooks/Functions";
import LikeUnlike from "./Favorite";


export default function PredesignTemplateView({ item, onUseDesign, children }) {
    return (
        <Card key={item.id} className="col-span-1 group w-full max-w-[400px] overflow-hidden relative">
            {children}
            <div className='md:bg-cover bg-contain bg-no-repeat group-hover:!bg-contain duration-300 bg-center h-[300px]' style={{ backgroundImage: `url("${item.preview}")` }} />
            <div className='text-sm px-4 w-full py-2 text-center font-semibold'>{item.name}</div>
            <LikeUnlike item={item} type="templates" />
            <div className="flex px-2 gap-2 py-2 flex-row justify-between items-center bg-transparent mt-1">
                <Button as="button" onClick={() => onUseDesign(item)} className='w-fit flex-1' size="sm">Use Design</Button>
                <Button as="button" onClick={() => navigate(route('home.preview-templates', { uuid: item.uuid }))} className='w-fit' size="sm">Preview </Button>
            </div>
        </Card>
    )
}
